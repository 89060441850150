import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { bannerIMG } from 'src/app/shared/models/bannerIMG';
import { CampoFormulario } from 'src/app/shared/models/perfil';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getBanners(): Observable<bannerIMG[]> {
    return this.http.get<bannerIMG[]>(this.baseUrl + 'profile').pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getCampos(): Observable<CampoFormulario[]> {
    return this.http
      .get<CampoFormulario[]>(this.baseUrl + 'profile/perfil-vendedor')
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  getCamposProvider(providerId: string): Observable<CampoFormulario[]> {
    return this.http
      .get<
        CampoFormulario[]
      >(this.baseUrl + 'profile/perfil-vendedor/' + providerId)
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  saveCampos(campos: CampoFormulario[]) {
    return this.http.post<CampoFormulario>(
      this.baseUrl + 'profile/guardar-campos',
      campos,
    );
  }
}
