import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { SharedModule } from '../shared/shared.module';
import { TopRankingComponent } from './top-ranking/top-ranking.component';
import { NewArrivalsComponent } from './new-arrivals/new-arrivals.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DealsComponent } from './deals/deals.component';

@NgModule({
  declarations: [
    HomeComponent,
    TopRankingComponent,
    NewArrivalsComponent,
    DealsComponent,
  ],
  imports: [CommonModule, SharedModule, NgbModule],
  exports: [
    HomeComponent,
    TopRankingComponent,
    NewArrivalsComponent,
    DealsComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomeModule {}
