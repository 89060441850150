<div class="tw-container mx-auto tw-px-4 tw-py-4">
  <p-menubar [model]="items" />
  <br />
  <p-carousel
    [value]="products"
    [numVisible]="6"
    [numScroll]="1"
    [circular]="true"
    [autoplayInterval]="2000"
    [showIndicators]="false"
    [showNavigators]="false"
    [responsiveOptions]="responsiveOptions"
  >
    <ng-template let-product pTemplate="item">
      <div class="product-card">
        <div class="card" routerLink="/shop/{{ product.id }}">
          <img [src]="product.pictureUrl" [alt]="product.name" />
        </div>
      </div>
    </ng-template>
  </p-carousel>
  <br />
  <br />
  <section class="tw-w-full">
    <h2 class="section-title">MÁS VENDIDOS</h2>

    <div
      class="tw-grid tw-grid-cols-2 sm:tw-grid-cols-2 md:tw-grid-cols-5 gap-4"
    >
      <ng-container *ngFor="let product of topSellers">
        <div class="product-card" routerLink="/shop/{{ product.id }}">
          <div class="card-normal">
            <img [src]="product.pictureUrl" [alt]="product.name" />
            <h3>{{ product.name }}</h3>
            <p>{{ product.price | currency }}</p>
          </div>
        </div>
      </ng-container>
    </div>
  </section>

  <section class="text-center p-4 mb-6 mx-auto" style="max-width: 42rem">
    <div class="text-center" style="max-width: 42rem">
      <div class="flex justify-center items-center tw-w-full">
        <img
          src="/assets/images/logo.jpg"
          alt=""
          class="mb-3 tw-w-full tw-h-auto tw-max-w-xs md:tw-max-w-sm lg:tw-max-w-md mx-auto"
        />
      </div>

      <p class="mb-4 uppercase text-2xl font-bold mb-6 leading-tight">
        EMPRESA CUYA MISIÓN ES DAR A CONOCER<br />
        MARCAS Y VENDEDORES QUE RESALTEN NUESTRO<br />
        COMERCIO MEXICANO
      </p>

      <button pButton label="NOSOTROS" class="p-button-rounded"></button>
    </div>
  </section>
  <section class="mb-6">
    <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-0">
      <div
        class="tw-bg-orange-500 tw-text-white tw-p-6 tw-flex tw-flex-col tw-items-center tw-justify-center"
      >
        <div class="tw-text-center" style="max-width: 24rem">
          <h2 class="tw-text-4xl tw-font-bold tw-mb-6 tw-leading-tight">
            SOLO TÚ SABES COMO HACER ÚNICA TU TIENDA
          </h2>
          <button
            pButton
            label="REGISTRATE GRATIS"
            routerLink="/account/register/provider"
            class="p-button-rounded p-button-secondary"
          ></button>
        </div>
      </div>
      <div class="tw-relative">
        <img
          [src]="urlReg"
          alt="Unique Store"
          class="tw-w-full tw-h-full tw-object-cover"
        />
      </div>
    </div>
  </section>
</div>
