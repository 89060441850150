<ngx-spinner type="timer">
  <h3>Loading...</h3>
</ngx-spinner>

<app-nav-bar></app-nav-bar>

<!--<div>
  <app-section-header></app-section-header>
</div>-->

<router-outlet>
  <p-toast key="toast"></p-toast>
  <p-messages key="msg"></p-messages>
</router-outlet>
<!-- Footer container -->
<footer class="bg-white">
  <div class="container py-5">
    <div class="row py-4">
      <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
        <img src="/assets/images/logo.jpg" alt="" width="180" class="mb-3" />
        <ul class="list-inline mt-4 ml-4">
          <li class="list-inline-item">
            <a href="#" target="_blank" title="twitter"
              ><i class="fa fa-twitter"></i
            ></a>
          </li>
          <li class="list-inline-item">
            <a href="#" target="_blank" title="facebook"
              ><i class="fa fa-facebook"></i
            ></a>
          </li>
          <li class="list-inline-item">
            <a href="#" target="_blank" title="instagram"
              ><i class="fa fa-instagram"></i
            ></a>
          </li>
          <li class="list-inline-item">
            <a href="#" target="_blank" title="pinterest"
              ><i class="fa fa-pinterest"></i
            ></a>
          </li>
          <li class="list-inline-item">
            <a href="#" target="_blank" title="vimeo"
              ><i class="fa fa-vimeo"></i
            ></a>
          </li>
        </ul>
      </div>
      <div class="col-lg-2 col-md-6 mb-4 mb-lg-0">
        <h6 class="text-uppercase font-weight-bold mb-4">Empresa</h6>
        <ul class="list-unstyled mb-0">
          <li class="mb-2"><a href="#" class="text-muted">About Us</a></li>
          <li class="mb-2">
            <a routerLink="/seguridad-y-envio" class="text-muted"
              >Seguridad y Envíos</a
            >
          </li>
          <li class="mb-2">
            <a routerLink="/atencion-a-clientes" class="text-muted"
              >Atención al Cliente</a
            >
          </li>
        </ul>
      </div>
      <div class="col-lg-2 col-md-6 mb-4 mb-lg-0">
        <h6 class="text-uppercase font-weight-bold mb-4">Acceso</h6>
        <ul class="list-unstyled mb-0">
          <li class="mb-2">
            <a routerLink="/account/login" class="text-muted">Login</a>
          </li>
          <li class="mb-2">
            <a routerLink="/account/register/client" class="text-muted"
              >Registro Cliente</a
            >
          </li>
          <li class="mb-2">
            <a routerLink="/account/register/provider" class="text-muted"
              >Registro Vendedor</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- Copyrights -->
  <div class="bg-light py-4">
    <div class="container text-center">
      <p class="text-muted mb-0 py-2">
        &copy; Viammex. Todos los derechos reservados.
      </p>
    </div>
  </div>
</footer>
