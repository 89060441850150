import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProductCategory1 } from '../shared/models/productcategory1';
import { Pagination } from '../shared/models/pagination';
import { Product, ProductToCreate } from '../shared/models/product';
import { ShopParams } from '../shared/models/shopParams';

@Injectable({
  providedIn: 'root',
})
export class ShopService {
  baseUrl = environment.apiUrl;
  products: Product[] = [];
  productcategory1: ProductCategory1[] = [];
  pagination?: Pagination<Product[]>;
  shopParams = new ShopParams();
  productCache = new Map<string, Pagination<Product[]>>();

  constructor(private http: HttpClient) {}

  getProducts(useCache = true): Observable<Pagination<Product[]>> {
    if (!useCache) this.productCache = new Map();

    if (this.productCache.size > 0 && useCache) {
      if (this.productCache.has(Object.values(this.shopParams).join('-'))) {
        this.pagination = this.productCache.get(
          Object.values(this.shopParams).join('-'),
        );
        if (this.pagination) return of(this.pagination);
      }
    }

    let params = new HttpParams();

    if (this.shopParams.categoryId > 0)
      params = params.append('categoryId', this.shopParams.categoryId);
    params = params.append('sort', this.shopParams.sort);
    params = params.append('pageIndex', this.shopParams.pageNumber);
    params = params.append('pageSize', this.shopParams.pageSize);
    if (this.shopParams.search)
      params = params.append('search', this.shopParams.search);

    return this.http
      .get<Pagination<Product[]>>(this.baseUrl + 'products', { params })
      .pipe(
        map((response) => {
          this.productCache.set(
            Object.values(this.shopParams).join('-'),
            response,
          );
          this.pagination = response;
          return response;
        }),
      );
  }

  getProductsProvider(): Observable<Product[]> {
    return this.http.get<Product[]>(this.baseUrl + 'products/provider').pipe(
      map((response) => {
        //this.productCache.set(Object.values(this.shopParams).join('-'), response)
        //this.pagination = response;
        return response;
      }),
    );
  }

  getProductsProviderId(id?: string): Observable<Product[]> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<Product[]>(
      this.baseUrl + 'products/providerId',
      JSON.stringify(id),
      { headers },
    );
  }

  setShopParams(params: ShopParams) {
    this.shopParams = params;
  }

  getShopParams() {
    return this.shopParams;
  }

  getProduct(id: number) {
    const product = [...this.productCache.values()].reduce(
      (acc, paginatedResult) => {
        return { ...acc, ...paginatedResult.data.find((x) => x.id === id) };
      },
      {} as Product,
    );

    if (Object.keys(product).length !== 0) return of(product);

    return this.http.get<Product>(this.baseUrl + 'products/' + id);
  }

  getProductCategory1() {
    if (this.productcategory1.length > 0) return of(this.productcategory1);

    return this.http
      .get<ProductCategory1[]>(this.baseUrl + 'products/category1')
      .pipe(
        map((productcategory1) => (this.productcategory1 = productcategory1)),
      );
  }

  createProduct(formData: any) {
    return this.http.post<Product>(this.baseUrl + 'products/save', formData);
  }

  deleteProduct(products: any[] | any) {
    return this.http.post<Product>(this.baseUrl + 'products/delete', products);
  }
}
