import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AccountService } from 'src/app/account/account.service';
import { BasketService } from 'src/app/basket/basket.service';
import { BasketItem } from 'src/app/shared/models/basket';
import { ShopParams } from 'src/app/shared/models/shopParams';
import { ShopService } from 'src/app/shop/shop.service';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {
  items: MenuItem[] = [];
  isMenuCollapsed = true; // This variable controls the collapse/expand state of the menu
  @ViewChild('search') searchTerm?: ElementRef;
  shopParams: ShopParams;

  constructor(public basketService: BasketService, public accountService: AccountService, public shopService:ShopService,private router:Router) {

    this.shopParams = shopService.getShopParams();


  }
  ngOnInit() {

  }

  getProducts() {
    this.shopService.getProducts()
    .subscribe({
      next: response => {
      },
      error: error => console.log(error)
    })
  }

  onSearch() {
    const params = this.shopService.getShopParams();
    params.search = this.searchTerm?.nativeElement.value;
    params.pageNumber = 1;
    this.shopService.setShopParams(params);
    this.shopParams = params;
    this.getProducts();
    this.router.navigate(['/shop']);
  }

  logout() {
    this.accountService.logout();
  }

  getCount(items: BasketItem[]) {
    return items.reduce((sum, item) => sum + item.quantity, 0);
  }

}
